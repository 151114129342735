export default {
  route: {
    login: '登录',
    dashboard: '首页',
    documentation: '文档',
    guide: '引导页',
    permission: '权限测试页',
    rolePermission: '角色权限',
    pagePermission: '页面权限',
    directivePermission: '指令权限',
    icons: '图标',
    components: '组件',
    tinymce: '富文本编辑器',
    markdown: 'Markdown',
    jsonEditor: 'JSON 编辑器',
    dndList: '列表拖拽',
    splitPane: 'Splitpane',
    avatarUpload: '头像上传',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: '小组件',
    backToTop: '返回顶部',
    dragDialog: '拖拽 Dialog',
    dragSelect: '拖拽 Select',
    dragKanban: '可拖拽看板',
    charts: '图表',
    keyboardChart: '键盘图表',
    lineChart: '折线图',
    mixChart: '混合图表',
    example: '综合实例',
    nested: '路由嵌套',
    menu1: '菜单1',
    'menu1-1': '菜单 1-1',
    'menu1-2': '菜单 1-2',
    'menu1-2-1': '菜单 1-2-1',
    'menu1-2-2': '菜单 1-2-2',
    'menu1-3': '菜单 1-3',
    menu2: '菜单 2',
    Table: 'Table',
    dynamicTable: '动态 Table',
    dragTable: '拖拽 Table',
    inlineEditTable: 'Table 内编辑',
    complexTable: '综合 Table',
    tab: 'Tab',
    form: '表单',
    createArticle: '创建文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    selectExcel: '导出 已选择项',
    mergeHeader: '导出 多级表头',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: '换肤',
    clipboardDemo: 'Clipboard',
    i18n: '国际化',
    externalLink: '外链',
    profile: '个人中心',
    movieManage: '影视管理',
    movieList: '电影列表',
    movieAdd: '新增 | 编辑电影',
    seriesList: '剧集列表',
    seriesAdd: '新增 | 编辑剧集',
    shortList: '短视频列表',
    shortAdd: '新增 | 编辑短视频',
    appManage: 'APP管理',
    xilieList: '系列列表',
    xilieAdd: '新增 | 编辑系列',
    tagsList: '标签管理',
    shortResources: '短视频资源列表',
    seriesResources: '剧集资源列表',
    movieResources: '电影资源列表',
    filterPanelConfig: '筛选列表配置',
    filterSecondLevelConfig: '影视配置',
    thirdSecondLevelConfig: '筛选条件配置',
    versionManage: '版本管理',
    serviceManage: '用户服务',
    commonPopup: '通用弹窗配置',
    feedbackList: '用户反馈',
    questionList: '反馈类型列表',
    questionManage: '问题管理',
    taskCenter: '任务中心',
    taskList: '任务列表',
    taskStore: '商城列表',
    integralDetail: '积分明细',
    taskAuditList: '任务审核',
    Withdraw: '提现管理',
    userManage: '用户管理',
    userList: '用户列表',
    userBlackList: '用户黑名单',
    whiteList: '用户白名单',
    userLabel: '用户和设备标签',
    fission: '裂变活动',
    activityManage: '活动管理',
    activityTemplate: '直播活动模版',
    templateAdd: '直播活动模版新增｜编辑',
    templateRank: '直播活动模版榜单',
    templateRankAdd: '直播活动模版榜单新增｜编辑',
    templateHonor: '荣誉堂编辑',
    templateReward: '奖励配置',
    searchSetting: '搜索设置',
    articleManage: '文章管理',
    promoteList: '外部推广',
    homeSetting: '首页配置',
    homeTvSetting: '首页配置-TV',
    homeComponents: '首页组件库',
    singleList: '片单管理',
    singleAddContent: '新增编辑片单',
    plateSetting: '板块内容管理',
    developManage: '开发者板块',
    dicList: '字典列表',
    yuque: '语雀',
    pushList: '推送列表',
    createPush: '新建推送',
    updatePush: '修改推送',
    pushDetail: '推送详情',
    authSetting: '权限设置',
    floatWindow: '首页悬浮窗',
    h5Share: 'H5分享配置',
    h5ShareAdd: 'H5分享新增|编辑',
    upList: 'UP主列表',
    regionList: '地区配置',
    adManage: '广告管理',
    adSwitch: '广告开关',
    homePageList: '首页页面设置',
    homeTvPageList: 'TV首页页面设置',
    vipManage: 'VIP管理',
    vipDiscountCase: '优惠方案',
    barSetting: '底bar设置',
    vipProduct: '商品管理',
    vipIssue: '发放VIP',
    vipActivityConfig: '临时VIP活动配置',
    vipCode: 'VIP Code码',
    vipCodeDetail: '兑换明细',
    vipDetail: 'VIP明细',
    userGroup: '用户分组',
    newGroup: '新建分组',
    groupManage: '分组管理',
    configurManage: '官网管理',
    configur: '官网配置',
    infoList: '文章资讯',
    telegramManage: 'Telegram管理',
    cdnSetting: 'CDN切换',
    ugc: 'UGC管理',
    auditList: 'UGC稿件审核',
    auditOp: '审核详情',
    starList: '明星列表',
    addStar: '新增 | 编辑明星',
    addFission: '新增 | 编辑活动',
    setRule: '设置规则',
    rewardList: '奖励列表',
    emojiList: '表情包管理',
    watchTogether: '一起看',
    messageList: '消息管理列表',
    sendList: '消息发送列表',
    crawlerManage: '爬虫管理',
    starInfoList: '明星信息',
    starInfoDetail: '查看明星信息',
    communityManage: '社区管理',
    dynamicManage: '帖子管理',
    commentManage: '评论管理',
    topicManage: '话题管理',
    topicManageAdd: '新增|编辑 话题',
    detailTabConfigManage: '详情页配置管理',
    discussDetail: '新增|编辑 讨论tab',
    playerDetail: '新增|编辑 播放器详情页配置',
    customDetail: '新增|编辑 自定义tab',
    Sensitive: '敏感词',
    contentManage: '内容管理',
    homeLargeWindow: '首页大弹窗',
    noteSwitchover: '短信切换',
    goodsAndOrder: '虚拟币管理',
    virtualCurrencyManage: '商品管理',
    provideManage: '发放管理',
    VirtualCurrencyManageContent: '页面设置|内容',
    virtualCurrencyItemized: '商品收支明细',
    orderItemized: '订单管理',
    goodsItemized: '礼物收支明细',
    inviteUserPage: '邀请新用户落地页设置',
    probabilityGame: '概率游戏模板',
    probabilityGameSetting: '游戏设置',
    probabilityGameSetting1: '游戏设置',
    officialSetting: '通知设置',
    starPk: '明星PK',
    addPkStar: '新增|编辑',
    starPkReport: '数据报表',
    starPkRewardSetting: '任务奖励设置',
    gatePass: '通行证管理',
    gatePassEdit: '通行证新增|编辑',
    fissionInvite: '裂变拉新',
    jackpotNumberSetting: '奖池数值设置',
    jackpotGiftSetting: '奖池礼物设置',
    dataReport: '数据报表',
    dataReport1: '数据报表',
    goodsList: '礼物列表',
    escortManage: '陪看管理',
    UserList: '陪看用户列表',
    CashOutManage: '提现管理',
    firstPaySettings: '首充设置',
    Settings: '相关配置管理',
    vipConfiguration: '相关配置',
    vipOrder: '订单管理',
    video: '查看资源内容',
    escortReportList: '陪看用户举报列表',
    escortOrderList: '陪看订单管理',
    divideRatio: '信誉值和订单分成比例管理',
    vipGoods: 'VIP商品配置',
    anchorLive: '主播管理',
    dayItemized: '主播日结明细',
    monthItemized: '主播月结明细',
    guildManage: '公会管理',
    liveDetail: '开播明细',
    cashOut: '提现管理',
    cashOutManage: '提现管理',
    liveConfig: '直播配置',
    liveSound: '直播间音效',
    bannerConfig: 'banner配置',
    PositionConfig: '推荐位配置',
    RecommendExposure: '曝光数据',
    HomeRecommendRange: '首页推荐位',
    liveSwitch: '直播间功能入口',
    runwayConfig: '跑道配置',
    musicUpload: '歌曲上传',
    hotSearchRecommendation: '热搜推荐池',
    atmosphereConfig: '氛围图配置',
    userTask: '用户任务管理',
    dressConfig: '装扮配置',
    anchorTask: '主播任务配置',
    dressItemized: '装扮收支明细',
    agentManage: '代理管理',
    punishRecord: '处罚记录',
    floatingConfig: '悬浮窗配置',
    liveRoom: '直播间管理',
    liveRoomRecommendRange: '人工推荐池',
    liveManage: '主播管理',
    gameSwitch: '游戏开关及道具配置',
    liveSettlement: '直播结算',
    anchorRecommend: '主播推荐池',
    incomeSettings: '结算配置',
    liveRoomCategory: '直播间分类列表配置',
    liveRoomCategoryListSecond: '二级分类设置',
    refundList: '退款列表',
    guildRoom: '公会房管理',
    guildRoomReport: '公会房数据',
    guildRoomReportCustom: '指定公会房数据报表',
    deviceManage: '登录设备限制管理',
    countryGroup: '国家组',
    appointment: '排期表',
    vipConfigurationManage: '配置列表',
    autoPushList: '自动化推送列表',
    emailRecall: '邮箱召回',
    liveFunctionConfig: '直播功能配置',
    liveRoomWindow: '直播间软更新弹窗',
    biscuitsExchange: '猫饼兑换',
    areaFilter: '地区筛选配置',
    sensitive: '敏感词库',
    vipSubscription: '订阅管理',
    episodePay: '剧付费管理',
    episodePayEdit: '新增|编辑付费内容',
    episodeBuyItemized: '剧购买明细',
    adList: '广告配置列表',
    luckGoods: '幸运礼物',
    report: '数据报表',
    poolSetting: '奖池设置',
    RevealAd: '兜底广告视频配置',
    brandTakeover: '开屏配置',
    labelConfig: '标签配置',
    labelSend: '标签发放',
    labelRecycle: '标签回收',
    reportCenter: '报表中心',
    rankManage: '排行榜管理',
    rankBlacklist: '排行榜黑名单',
    rankList: '榜单管理',
    shortRecommendation: '短剧推荐池',
    shortsSearchDramaConfig: '找剧组件配置',
    searchDramaLabelRecommendation: '找剧标签推荐池',
    sharing: '分成管理',
    shortManage: '短剧管理',
    shortsNavigationConfig: '短剧导航栏配置',
    shortPageSetting: '短剧页面设置',
    recommendationContent: '推荐池内容',
    withdrawInShort: '提现管理'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips:
      '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定'
  },
  example: {
    warning:
      '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description:
      '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  btn: {
    back: '返回',
    publish: '提交',
    next: '下一步',
    previous: '上一步'
  }
}
