import Layout from '@/layout';

const userManageRouter = {
  path: '/user-manage',
  component: Layout,
  redirect: '/user-manage/user-list',
  name: 'UserManage',
  meta: {
    title: 'userManage',
    icon: 'people'
  },
  children: [
    {
      path: 'user-list',
      component: () => import('@/views/user-manage/user/list'),
      name: 'UserList',
      meta: { title: 'userList', noCache: false }
    },
    {
      path: 'user-black-list',
      component: () => import('@/views/user-manage/black-list/index'),
      name: 'UserBlackList',
      meta: { title: 'userBlackList', noCache: false }
    },
    {
      path: 'user-label',
      component: () => import('@/views/user-manage/user-label/index'),
      name: 'UserLabel',
      meta: { title: 'userLabel', noCache: false }
    },
    {
      path: 'white-list',
      component: () => import('@/views/user-manage/white-list/index'),
      name: 'WhiteList',
      meta: { title: 'whiteList', noCache: false }
    }
  ]
};

export default userManageRouter;
