/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const movieManageRouter = {
  path: '/movie-manage',
  component: Layout,
  redirect: '/movie-manage/movie-list',
  name: 'MovieManage',
  meta: {
    title: 'movieManage',
    icon: 'chart'
  },
  children: [
    {
      path: 'movie-list',
      component: () => import('@/views/movie-manage/movie/list'),
      name: 'MovieList',
      meta: { title: 'movieList', noCache: false }
    },
    {
      path: 'movie-add',
      component: () => import('@/views/movie-manage/movie/add'),
      name: 'MovieAdd',
      meta: { title: 'movieAdd', noCache: true },
      hidden: true
    },
    {
      path: 'movie-resources',
      component: () => import('@/views/movie-manage/movie/resources'),
      name: 'MovieResources',
      meta: { title: 'movieResources', noCache: true },
      hidden: true
    },
    {
      path: 'series-list',
      component: () => import('@/views/movie-manage/series/list'),
      name: 'SeriesList',
      meta: { title: 'seriesList', noCache: false }
    },
    {
      path: 'series-add',
      component: () => import('@/views/movie-manage/series/add'),
      name: 'SeriesAdd',
      meta: { title: 'seriesAdd', noCache: true },
      hidden: true
    },
    {
      path: 'series-resources',
      component: () => import('@/views/movie-manage/series/resources'),
      name: 'SeriesResources',
      meta: { title: 'seriesResources', noCache: true },
      hidden: true
    },
    {
      path: 'short-list',
      component: () => import('@/views/movie-manage/short/list'),
      name: 'ShortList',
      meta: { title: 'shortList', noCache: false }
    },
    {
      path: 'short-add',
      component: () => import('@/views/movie-manage/short/add'),
      name: 'ShortAdd',
      meta: { title: 'shortAdd', noCache: true },
      hidden: true
    },
    {
      path: 'short-resources',
      component: () => import('@/views/movie-manage/short/resources'),
      name: 'ShortResources',
      meta: { title: 'shortResources', noCache: true },
      hidden: true
    },
    {
      // 系列列表 漫威系列，等等
      path: 'xilie-list',
      component: () => import('@/views/movie-manage/xilie/list'),
      name: 'xilieList',
      meta: { title: 'xilieList', noCache: true }
    },
    {
      // 标签管理
      path: 'tags-list',
      component: () => import('@/views/movie-manage/tags/list'),
      name: 'tagsList',
      meta: { title: 'tagsList', noCache: true }
    },
    {
      path: 'single-list',
      component: () => import('@/views/movie-manage/single/list'),
      name: 'SingleList',
      meta: { title: 'singleList', noCache: false }
    },
    {
      path: 'single-add-content',
      component: () => import('@/views/movie-manage/single/add-content/index'),
      name: 'SingleAddContent',
      meta: { title: 'singleAddContent', noCache: true },
      hidden: true
    },
    {
      path: 'up-list',
      component: () => import('@/views/movie-manage/up/list'),
      name: 'UpList',
      meta: { title: 'upList', noCache: false }
    },
    {
      path: 'star-list',
      component: () => import('@/views/movie-manage/star/list'),
      name: 'StarList',
      meta: { title: 'starList', noCache: false }
    },
    {
      path: 'add-star',
      component: () => import('@/views/movie-manage/star/add'),
      name: 'AddStar',
      meta: { title: 'addStar', noCache: false }
    },
    {
      path: 'content-manage',
      component: () => import('@/views/movie-manage/content/list'),
      name: 'ContentManage',
      meta: { title: 'contentManage', noCache: false }
    },
    {
      path: 'video',
      component: () => import('@/views/movie-manage/video'),
      name: 'Video',
      meta: { title: 'video', noCache: true }
    },
    {
      path: 'country-group',
      component: () => import('@/views/movie-manage/country-group'),
      name: 'CountryGroup',
      meta: { title: 'countryGroup', noCache: true }
    },
    {
      path: 'appointment',
      component: () => import('@/views/movie-manage/appointment'),
      name: 'Appointment',
      meta: { title: 'appointment', noCache: false }
    }
  ]
}

export default movieManageRouter
