import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/auth/backend/account/login',
    method: 'post',
    data
  });
}

export function getInfo(params) {
  return request({
    url: '/auth/backend/account/info',
    method: 'get',
    params
  });
}

export function logout() {
  return request({
    url: '/auth/backend/account/logout',
    method: 'put',
    loading: true
  });
}

export function allUserLogout() {
  return request({
    url: '/auth/backend/account/all_user_logout',
    method: 'put',
    loading: true
  });
}

export function queryUserDetail(params) {
  return request({
    url: '/user/backend/detail',
    method: 'get',
    params,
    loading: true
  });
}

export function updateUserInfo(data) {
  return request({
    url: '/user/backend/info/edit',
    method: 'post',
    data,
    loading: true
  });
}

export function resetPortrait(data) {
  return request({
    url: '/user/backend/info/resetPortrait',
    method: 'post',
    data,
    loading: true
  });
}

export function getUserPunishByUserId(params) {
  return request({
    url: '/user/behavior/backend/feedback/report/user/getPunishByUserId',
    method: 'put',
    params,
    loading: true
  });
}

export function punishUserReport(data) {
  return request({
    url: '/user/behavior/backend/feedback/report/user/punishByUserPage',
    method: 'put',
    data,
    loading: true
  });
}

// 用户列表-查询功能状态
export function getAccountStatus(data) {
  return request({
    url: '/user/backend/monitor/getAccountStatus',
    method: 'post',
    data
  });
}

// 用户列表-修改支付、提现状态
export function changeAccountStatus(data) {
  return request({
    url: '/user/backend/monitor/account/disabled',
    method: 'post',
    data
  });
}
// 用户黑名单 新增
export function saveBlacklist(data) {
  return request({
    url: '/user/backend/blacklist/save',
    method: 'post',
    data,
    loading: true
  });
}
// 用户黑名单 删除
export function delBlacklist(id) {
  return request({
    url: `/user/backend/blacklist/delete/${id}`,
    method: 'post'
  });
}

//启用或者禁用标签配置
export function enableUser(data) {
  return request({
    url: `/user/backend/tag/config/enable`,
    method: 'post',
    data,
    loading: true
  });
}

//编辑标签
export function updateUser(data) {
  return request({
    url: `/user/backend/tag/config/update`,
    method: 'post',
    data,
    loading: true
  });
}

export function whitelistDetail() {
  return request({
    url: '/user/backend/whitelist/detail',
    method: 'get',
    loading: true
  });
}

export function whitelistUpdate(data) {
  return request({
    url: `/user/backend/whitelist/update`,
    method: 'post',
    data,
    loading: true
  });
}

export function whitelistRemove(params) {
  return request({
    url: '/user/backend/whitelist/remove',
    method: 'get',
    params,
    loading: true
  });
}
