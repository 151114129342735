/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const goodsAndOrder = {
  path: '/goods-and-order',
  component: Layout,
  redirect: '/goods-and-order/virtual-currency-manage',
  name: 'GoodsAndOrder',
  meta: {
    title: 'goodsAndOrder',
    icon: 'chart'
  },
  children: [
    {
      path: 'virtual-currency-manage',
      component: () => import('@/views/goods-and-order/virtual-currency/list'),
      name: 'VirtualCurrencyManage',
      meta: { title: 'virtualCurrencyManage', noCache: false }
    },
    {
      path: 'provide-manage',
      component: () => import('@/views/goods-and-order/provide-manage/index'),
      name: 'ProvideManage',
      meta: { title: 'provideManage', noCache: false }
    },
    {
      path: 'virtual-currency-manage-content/:marketId',
      component: () => import('@/views/goods-and-order/virtual-currency/page-setting/content/list'),
      name: 'VirtualCurrencyManageContent',
      meta: { title: 'VirtualCurrencyManageContent', noCache: false },
      hidden: true
    },
    {
      path: 'virtual-currency-itemized',
      component: () => import('@/views/goods-and-order/virtual-currency-itemized/list'),
      name: 'VirtualCurrencyItemized',
      meta: { title: 'virtualCurrencyItemized', noCache: false }
    },
    {
      path: 'order-itemized',
      component: () => import('@/views/goods-and-order/order-itemized/list'),
      name: 'OrderItemized',
      meta: { title: 'orderItemized', noCache: false }
    },
    {
      path: 'goods-itemized',
      component: () => import('@/views/goods-and-order/goods-itemized/list'),
      name: 'GoodsItemized',
      meta: { title: 'goodsItemized', noCache: false }
    },
    {
      path: 'goods-list',
      component: () => import('@/views/goods-and-order/goods-list/'),
      name: 'GoodsList',
      meta: { title: 'goodsList', noCache: false }
    },
    {
      path: 'first-pay-settings',
      component: () => import('@/views/goods-and-order/first-pay-settings/'),
      name: 'FirstPaySettings',
      meta: { title: 'firstPaySettings', noCache: false }
    },
    // 装扮收支明细
    {
      path: 'dress-itemized',
      component: () => import('@/views/goods-and-order/dress-itemized/index'),
      name: 'DressItemized',
      meta: { title: 'dressItemized', noCache: false }
    },
    // 代理管理
    {
      path: 'agent-manage',
      component: () => import('@/views/goods-and-order/agent-manage/index'),
      name: 'AgentManage',
      meta: { title: 'agentManage', noCache: false }
    },
    {
      path: 'luck-goods',
      component: () => import('@/views/goods-and-order/luck-goods/'),
      name: 'LuckGoods',
      meta: { title: 'luckGoods', noCache: false }
    },
    {
      path: 'pool-setting/:type/:id', //type:0=币出币 1=币出装扮
      component: () => import('@/views/goods-and-order/luck-goods/pool-setting'),
      name: 'PoolSetting',
      meta: { title: 'poolSetting', noCache: false }
    },
    {
      path: 'report/:type', //type:0=币出币 1=币出装扮
      component: () => import('@/views/goods-and-order/luck-goods/report'),
      name: 'Report',
      meta: { title: 'report', noCache: false }
    }
  ]
}

export default goodsAndOrder
